.contact-form {
    display: flex;
    padding: 7vh;
    justify-content: center;
    gap: 150px;
}

.footer-info {
    display: flex;
    flex-direction: column;
}

.appointment-request-header {
    max-width: 500px;
}

#appointment-header {
    margin-top: 0px;
}

.radio-container {
    display: flex;
    align-items: center;
    gap: 15px;
    padding-bottom: 15px;
}

.radio-container2 {
    display: flex;
    gap: 2px;
}

.radio-container3 {
    display: flex;
    align-items: center;
    gap: 15px;
}

.radio {
    display: flex;
}

#radio {
    margin-left: 0px;
    width: 15px;
    margin-bottom: 2.5px;
}

#preferred-text {
    margin-top: -10px;
}

.contact-image {
    display: flex;
    flex-direction: column;
    max-width: 50%;
}

#contact-image {
    max-width: 100%;
}

.about-header {
    margin-top: 0vh;
}

.contact-page {
    
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
}

input {
    border: solid;
    border-width: 1px;
}

textarea {
    border: solid;
    border-width: 1px;
    font-family: "Manrope", sans-serif;
    font-size: 20px;
}


.contact-input {
    font-size: 16px;
}

#contact-input3 {
    width: 484px;
    
}

#contact-input2 {
    padding: 2vh;
    width: 484px;
}

.name {
    display: flex;
    gap: 50px;
}

input {
    padding: 2vh;
    width: 200px;
    font-size: 20px;
}


.footer-hours-time {
    display: flex;
    font-weight: 100;
    font-size: 22px;
}

.footer-hours {
   padding: 4vh 0vh 2vh 0vh;
}


#footer-day {
    font-family: "Manrope", sans-serif;
    min-width: 125px;
}

h5 {
    font-weight: 300;
    margin-top: 10px;
    font-size: 18px;
    margin-bottom: 10px;
}

.footer-phone {
    font-family: "Manrope", sans-serif;
    font-weight: 300;
}

#footer-day2 {
    font-family: "Manrope", sans-serif;
    min-width: 125px;
    
}

.footer-hours-time > h5{
    margin-top: 2px;
    margin-bottom: 1px;
}

.footer-hours-time2 {
    padding-top: 30px;
}

.date-time {
    display: flex;
    gap: 20px;
}

.confirmation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10vh;
    background-image: url('../images/line.png');
    background-size: cover;
}

@media only screen and (max-width: 430px) {
    .contact-form {
        padding: 2vh;
    }

    .confirmation {
        padding: 1vh;
    }

    .contact-image {
        max-width: 100%;
        padding-right: 0vh !important;
    }

    .contact-image {
        max-width: 100% !important;
    }

    #footer-day {
        font-family: "Manrope", sans-serif;
        min-width: 115px;
    }

    .radio-container {
        gap: 5px;
    }

    .radio-container2 {
        gap: 5px;
    }

    #radio {
        width: 30px;
    }

}

@media only screen and (max-width: 1140px){
    .contact-form {
        gap: 50px;
    }
}

@media only screen and (max-width: 1032px){
    .contact-form {
        align-items: center;
        flex-direction: column;
        gap: 50px;
    }

    .contact-image {
        max-width: 100%;
        padding-right: 17vh;
    }
}



/* contact component responsiveness */
/* @media only screen and (max-width: 920px){
    input {
        width: 120px;
    }

    #contact-input2 {
        width: 325px;
    }

    #contact-input3 {
        width: 325px;
    }
} */

/* @media only screen and (max-width: 770px){
    input {
        width: 220px;
    }

    #contact-input2 {
        width: 524px;
    }

    #contact-input3 {
        width: 524px;
    }

    .contact-form {
        flex-direction: column;
    }
} */

@media only screen and (max-width: 660px){
    input {
        width: 180px;
    }

    .contact-image {
        max-width: 80%;
    }

    #contact-input2 {
        width: 446px;
    }

    #contact-input3 {
        width: 446px;
    }

    .contact-form {
        flex-direction: column;
    }
}

@media only screen and (max-width: 580px){
    input {
        width: 120px;
    }

    .contact-image {
        max-width: 80%;
    }

    #contact-input2 {
        width: 325px;
    }

    #contact-input3 {
        width: 325px;
    }

    .contact-form {
        flex-direction: column;
    }
}

@media only screen and (max-width: 685px){

    .contact {
        display: flex;
        align-items: center;
        flex-direction: column !important;
        gap: 50px;
        text-align: center;
    }
}